/*
 * HTML5 Boilerplate
 *
 * What follows is the result of much research on cross-browser styling.
 * Credit left inline and big thanks to Nicolas Gallagher, Jonathan Neal,
 * Kroc Camen, and the H5BP dev community and team.
 */
/* =============================================================================
   HTML5 element display
   ========================================================================== */
article, aside, details, figcaption, figure, footer, header, hgroup, nav, section {
  display: block;
}

audio[controls], canvas, video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

/* =============================================================================
   Base
   ========================================================================== */
/*
 * 1. Correct text resizing oddly in IE6/7 when body font-size is set using em units
 *    http://clagnut.com/blog/348/#c790
 * 2. Force vertical scrollbar in non-IE
 * 3. Remove Android and iOS tap highlight color to prevent entire container being highlighted
 *    www.yuiblog.com/blog/2010/10/01/quick-tip-customizing-the-mobile-safari-tap-highlight-color/
 * 4. Prevent iOS text size adjust on device orientation change, without disabling user zoom
 *    www.456bereastreet.com/archive/201012/controlling_text_size_in_safari_for_ios_without_disabling_user_zoom/
 */
html {
  font-size: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  margin: 0;
  font-size: 13px;
  line-height: 1.231;
}

body, button, input, select, textarea {
  font-family: sans-serif;
  color: #222;
}

/*
 * These selection declarations have to be separate
 * No text-shadow: twitter.com/miketaylr/status/12228805301
 * Also: hot pink!
 */
::-moz-selection {
  background: #fe57a1;
  color: #fff;
  text-shadow: none;
}

::selection {
  background: #fe57a1;
  color: #fff;
  text-shadow: none;
}

/* =============================================================================
   Links
   ========================================================================== */
a {
  color: #00e;
}

a:visited {
  color: #551a8b;
}

a:focus {
  outline: thin dotted;
}

/* Improve readability when focused and hovered in all browsers: people.opera.com/patrickl/experiments/keyboard/test */
a:hover, a:active {
  outline: 0;
}

/* =============================================================================
   Typography
   ========================================================================== */
abbr[title] {
  border-bottom: 1px dotted;
}

b, strong {
  font-weight: bold;
}

blockquote {
  margin: 1em 40px;
}

dfn {
  font-style: italic;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

ins {
  background: #ff9;
  color: #000;
  text-decoration: none;
}

mark {
  background: #ff0;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

/* Redeclare monospace font family: en.wikipedia.org/wiki/User:Davidgothberg/Test59 */
pre, code, kbd, samp {
  font-family: monospace, monospace;
  _font-family: 'courier new', monospace;
  font-size: 1em;
}

/* Improve readability of pre-formatted text in all browsers */
pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

q {
  quotes: none;
}

q:before, q:after {
  content: "";
  content: none;
}

small {
  font-size: 85%;
}

/* Position subscript and superscript content without affecting line-height: gist.github.com/413930 */
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* =============================================================================
   Lists
   ========================================================================== */
ul, ol {
  margin: 1em 0;
  padding: 0 0 0 40px;
}

dd {
  margin: 0 0 0 40px;
}

nav ul, nav ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* =============================================================================
   Embedded content
   ========================================================================== */
/*
 * Improve image quality when scaled in IE7
 * code.flickr.com/blog/2008/11/12/on-ui-quality-the-little-things-client-side-image-resizing/
 */
img {
  border: 0;
  -ms-interpolation-mode: bicubic;
}

/*
 * Correct overflow displayed oddly in IE9
 */
svg:not(:root) {
  overflow: hidden;
}

/* =============================================================================
   Figures
   ========================================================================== */
figure {
  margin: 0;
}

/* =============================================================================
   Forms
   ========================================================================== */
form {
  margin: 0;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

/*
 * 1. Correct color not inheriting in IE6/7/8/9
 * 2. Correct alignment displayed oddly in IE6/7
 */
legend {
  border: 0;
  *margin-left: -7px;
  padding: 0;
}

/* Indicate that 'label' will shift focus to the associated form element */
label {
  cursor: pointer;
}

/*
 * 1. Correct font-size not inheriting in all browsers
 * 2. Remove margins in FF3/4 S5 Chrome
 * 3. Define consistent vertical alignment display in all browsers
 */
button, input, select, textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle;
}

/*
 * 1. Define line-height as normal to match FF3/4 (set using !important in the UA stylesheet)
 * 2. Correct inner spacing displayed oddly in IE6/7
 */
button, input {
  line-height: normal;
  *overflow: visible;
}

/*
 * 1. Display hand cursor for clickable form elements
 * 2. Allow styling of clickable form elements in iOS
 */
button, input[type="button"], input[type="reset"], input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
}

/*
 * Consistent box sizing and appearance
 */
input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
}

input[type="search"] {
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

/*
 * Remove inner padding and border in FF3/4
 * www.sitepen.com/blog/2008/05/14/the-devils-in-the-details-fixing-dojos-toolbar-buttons/
 */
button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/* Remove default vertical scrollbar in IE6/7/8/9 */
textarea {
  overflow: auto;
  vertical-align: top;
}

/* Colors for form validity */
input:invalid, textarea:invalid {
  background-color: #f0dddd;
}

/* =============================================================================
   Tables
   ========================================================================== */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* =============================================================================
   Primary styles
   Author: Casey Farrell, Takeflight
   ========================================================================== */
body {
  background: #f2efe2;
  margin: 10px 0;
  color: #231f20;
  font-family: 'Yanone Kaffeesatz', 'Trebuchet MS', Arial, sans-serif;
  font-size: 14px;
}

#container {
  width: 980px;
  margin: 0px auto;
}

#logo {
  float: left;
  margin-right: 15px;
}

.nav {
  float: left;
  padding: 0;
  margin: 0;
  list-style-type: none;
  text-transform: lowercase;
  font-size: 17px;
}

.nav li {
  display: inline-block;
  list-style-type: none;
}

.nav li a {
  text-decoration: none;
  display: inline-block;
  color: inherit;
  height: 18px;
  line-height: 18px;
  margin: 14px 0;
  padding: 0 9px;
  border-right: 1px solid #231f20;
}

.nav li:first-child a {
  border-left: 1px solid #231f20;
}

.nav a:hover {
  color: #bdbdb6;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.nav li.lineage a {
  color: #bdbdb6;
  border-color: #bdbdb6;
}

.nav li.current a {
  color: #bdbdb6;
}

#menu-social {
  float: right;
}

#main {
  clear: both;
  padding-top: 80px;
  position: relative;
}

h1 {
  font-weight: normal;
  font-size: 19px;
  position: absolute;
  right: 0;
  width: 270px;
}

.twocol h1 {
  width: 570px;
}

.wide h1 {
  width: 340px;
}

.col1, .col2, .col-wide {
  width: 270px;
  position: absolute;
  top: 150px;
}

.contact {
  top: 100px;
}

.col1 {
  right: 300px;
}

.col2 {
  right: 0;
}

.col2.wide {
  width: 300px;
  right: 40px;
  top: 130px;
}

.col-wide {
  width: 570px;
  right: 0;
}

.menu-pattern {
  width: 242px;
  margin: 0px auto;
  float: none;
}

.menu-pattern li {
  height: 121px;
  width: 121px;
  background: transparent url(../images/patterns3.jpg) top left no-repeat;
  position: relative;
  float: left;
}

.menu-pattern li.item1, .menu-pattern.animated li.item1 .fade-bg {
  background-position: 0 0;
}

.menu-pattern li.item2, .menu-pattern.animated li.item2 .fade-bg {
  background-position: -121px 0;
}

.menu-pattern li.item3, .menu-pattern.animated li.item3 .fade-bg {
  background-position: 0px -121px;
}

.menu-pattern li.item4, .menu-pattern.animated li.item4 .fade-bg {
  background-position: -121px -121px;
}

.menu-pattern li a {
  display: block;
  height: 121px;
  padding: 0;
  margin: 0;
  border: none !important;
  position: relative;
  z-index: 10;
}

.menu-pattern li a span {
  color: inherit;
  text-transform: uppercase;
  opacity: 0;
  height: 20px;
  width: 243px;
  display: block;
  position: absolute;
  top: -40px;
  left: -61px;
  text-align: center;
  color: #f2efe2;
  z-index: 9;
}

.menu-pattern li a:hover span {
  color: #231f20;
  opacity: 1;
  -webkit-transition: all 1.5s;
  -moz-transition: all 1.5s;
  -o-transition: all 1.5s;
  -ms-transition: all 1.5s;
  transition: all 1.5s;
  z-index: 10;
}

.menu-pattern li a:hover {
  background: transparent;
}

.menu-pattern li.item3 a span, .menu-pattern li.item4 a span {
  top: auto;
  bottom: -40px;
}

.fade-bg {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 121px;
  width: 121px;
  z-index: 1;
}

.item3 .fade-bg, .item4 .fade-bg {
  bottom: auto;
  top: 0;
}

/* =============================================================================
   Non-semantic helper classes
   Please define your styles before this section.
   ========================================================================== */
/* For image replacement */
.ir {
  display: block;
  text-indent: -999em;
  overflow: hidden;
  background-repeat: no-repeat;
  text-align: left;
  direction: ltr;
}

.ir br {
  display: none;
}

/* Hide for both screenreaders and browsers:
   css-discuss.incutio.com/wiki/Screenreader_Visibility */
.hidden {
  display: none;
  visibility: hidden;
}

/* Hide only visually, but have it available for screenreaders: by Jon Neal.
  www.webaim.org/techniques/css/invisiblecontent/  &  j.mp/visuallyhidden */
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/* Extends the .visuallyhidden class to allow the element to be focusable when navigated to via the keyboard: drupal.org/node/897638 */
.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

/* Hide visually and from screenreaders, but maintain layout */
.invisible {
  visibility: hidden;
}

/* Contain floats: nicolasgallagher.com/micro-clearfix-hack/ */
.clearfix:before, .clearfix:after {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  zoom: 1;
}

/* =============================================================================
   PLACEHOLDER Media Queries for Responsive Design.
   These override the primary ('mobile first') styles
   Modify as content requires.
   ========================================================================== */
@media only screen and (min-width: 480px) {
  /* Style adjustments for viewports 480px and over go here */
}

@media only screen and (min-width: 768px) {
  /* Style adjustments for viewports 768px and over go here */
}

/* =============================================================================
   Print styles.
   Inlined to avoid required HTTP connection: www.phpied.com/delay-loading-your-print-css/
   ========================================================================== */
@media print {
  * {
    background: transparent !important;
    color: black !important;
    text-shadow: none !important;
    filter: none !important;
    -ms-filter: none !important;
  }
  /* Black prints faster: sanbeiji.com/archives/953 */
  a, a:visited {
    color: #444 !important;
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .ir a:after, a[href^="javascript:"]:after, a[href^="#"]:after {
    content: "";
  }
  /* Don't show links for images, or javascript/internal links */
  pre, blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  /* css-discuss.incutio.com/wiki/Printing_Tables */
  tr, img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 0.5cm;
  }
  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }
  h2, h3 {
    page-break-after: avoid;
  }
}
